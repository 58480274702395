import styled from "styled-components";
import { Header, Sidebar } from "../components/index";
import { useContext, useEffect, useRef, useState } from "react";
import {
  TitleText,
  SubText,
  Content,
  DescBox,
  NeutralButton,
  BlueButton,
  RedButton,
  Card,
  Switches,
  OutlinedBox,
  FeatureText,
} from "../styles";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase/firebase";
import { storage } from "../firebase/firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useMediaQuery } from "@mui/material";
import { doc, updateDoc, deleteDoc, getDoc } from "firebase/firestore";
import toast from "react-hot-toast";
import ModalImage from "react-modal-image";
import { updateEmail, updatePassword } from "firebase/auth";
import Loader from "../pages/Loader";
import BillingModal from "../modals/BillingModal";
import UpgradeAccountModal from "../modals/UpgradeAccountModal";
import DeleteAccountModal from "../pages/DeleteAccountModal";
import DeleteAccountAdmin from "../modals/DeleteAccountAdmin";
import PlaceTradeModal from "../modals/PlaceTradeModal";
import SendNotificationModal from "../modals/SendNotificationModal";
import TradingProgressModal from "../modals/TradingProgressModal";
import AddDepositModal from "../modals/AddDepositModal";
import AddSignalBalanceModal from "../modals/AddSignalBalanceModal";
import AddUserProfitsModal from "../modals/AddUserProfitsModal";
import PopupModal from "../modals/PopupModal";
import AddSubscriptionBalanceModal from "../modals/AddSubscriptionBalanceModal";

const Profile = ({ user, id }) => {
  const [menuSmall, setMenuSmall] = useState(false);
  const mobile = useMediaQuery("(max-width: 900px)");
  const [isOpen, setIsOpen] = useState(mobile ? false : true);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(user !== null ? false : true);
  // const { userData } = useContext(userContext);
  const [userData, setUserData] = useState([]);
  const options = ["Profile", "Security"];
  const [activeOption, setActiveOption] = useState("Profile");
  const [isLoading, setIsLoading] = useState(true);
  const [picture, setPicture] = useState("");
  const imageRef = useRef();
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const [email, setEmail] = useState(userData?.email);
  const [firstName, setFirstName] = useState(userData?.firstname);
  const [lastName, setLastName] = useState(userData?.lastname);
  const [country, setCountry] = useState(userData?.country);
  const [isSavingProfile, setIsSavingProfile] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [selectedDeleteAccount, setSelectedDeleteAccount] = useState(false);
  const [userAccount, setUserAccount] = useState("");
  const [billing, setBilling] = useState(false);
  const [isBilling, setIsBilling] = useState(false);
  const [upgradeAccount, setUpgradeAccount] = useState(false);
  const [isUpgrading, setIsUpgrading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [placeTrade, setPlaceTrade] = useState(false);
  const [isTrading, setIsTrading] = useState(false);
  const [sendNotification, setSendNotification] = useState(false);
  const [isSendingNotification, setIsSendingNotification] = useState(false);

  const [tradingProgress, setTradingProgress] = useState(false);
  const [isTradingProgress, setIsTradingProgress] = useState(false);

  const [popup, setPopup] = useState(false);
  const [isPopup, setIsPopup] = useState(false);

  const [addDeposit, setAddDeposit] = useState(false);
  const [isAddingDeposit, setIsAddingDeposit] = useState(false);

  const [bal, setBal] = useState(undefined);
  const [addSignalBalance, setAddSignalBalance] = useState(false);
  const [isAddingSignalBalance, setIsAddingSignalBalance] = useState(false);

  const [subBal, setSubBal] = useState(undefined);
  const [addSubscriptionBalance, setAddSubscriptionBalance] = useState(false);
  const [isAddingSubscriptionBalance, setIsAddingSubscriptionBalance] = useState(false);

  const [profits, setProfits] = useState(0)
  const [addProfits, setAddProfits] = useState(false);
  const [isAddingProfits, setIsAddingProfits] = useState(false);

  const [isRemovingPopup, setIsRemovingPopup] = useState(false);
  const [removedPopup, setRemovedPopup] = useState(false);

  useEffect(() => {
    if (!user) {
      setIsLoading(true);
    }

    if (user) {
      setUserData(user);
      setIsLoading(false);
    }
  }, [user]);

  async function uploadPicture(newURL) {
    const q = doc(db, "users", userData.id);
    try {
      await updateDoc(q, {
        photoURL: newURL,
      });
      toast.success("Updated successfully");
    } catch (error) {
      // console.log(error);
    }
  }

  function handleAddSignalBalance() {
    setIsAddingSignalBalance(true);
    getBalance();

    async function getBalance() {
      const docRef = doc(db, "balance", userData.id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        const { signalBalance } = data;

        setBal(signalBalance);
        setIsAddingSignalBalance(false);
        setAddSignalBalance(true);
      } else {
        console.log("No such document!");
      }
    }
  }

  function handleAddSubscriptionBalance() {
    
    setIsAddingSubscriptionBalance(true);
    getSubscriptionBalance();

    async function getSubscriptionBalance() {
      const docRef = doc(db, "subscription", userData.id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        const { subscriptionBalance } = data;

        setSubBal(subscriptionBalance);
        setIsAddingSubscriptionBalance(false);
        setAddSubscriptionBalance(true);
      } else {
        console.log("No such document!");
      }
    }
  }

  async function handleDelete() {
    setIsDeleting(true);
    setShowDeleteAccountModal(true);

    setTimeout(() => {
      setIsDeleting(false);
    }, 2500);
  }

  function handleImageChange() {
    imageRef.current.click();
    setIsUploadingImage(true);

    setTimeout(() => {
      setIsUploadingImage(false);
    }, 8000);
  }

  function handleSendNoti() {
    setIsSendingNotification(true);

    setSendNotification(true);

    setTimeout(() => {
      setIsSendingNotification(false);
    }, 2500);
  }

  function handleTrade() {
    setIsTrading(true);

    setPlaceTrade(true);

    setTimeout(() => {
      setIsTrading(false);
    }, 2500);
  }

  function handleImageURL(e) {
    const imageLink = e.target.files[0];

    if (imageLink) {
      const url = URL.createObjectURL(imageLink);
      setPicture(url);
      // console.log(imageLink);
      const storageRef = ref(storage, imageLink.name + new Date());
      const uploadTask = uploadBytesResumable(storageRef, imageLink);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              // console.log("Upload is paused");
              break;
            case "running":
              // console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          // console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            // console.log("File available at", downloadURL);
            uploadPicture(downloadURL);
          });
        }
      );
    }
  }

  function handleTradingProgress() {
    setIsTradingProgress(true);

    setTimeout(() => {
      setTradingProgress(true);
      setIsTradingProgress(false);
    }, 500);
  }

  function handlePopup() {
    setIsPopup(true);

    setTimeout(() => {
      setPopup(true);
      setIsPopup(false);
    }, 500);
  }

  function handleAddDeposit() {
    setIsAddingDeposit(true);

    setTimeout(() => {
      setAddDeposit(true);
      setIsAddingDeposit(false);
    }, 500);
  }

  function handleAddProfits() {
    setIsAddingProfits(true);
    getProfits()

    async function getProfits(){
      const docRef = doc(db, "profits", userData.id);
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        const data = docSnap.data()
        const {profits} = data;
        setProfits(profits)
        setIsAddingProfits(false)
        setAddProfits(true)
      } else {
        console.log("No such document!");
      }

    
  }
}

  // useEffect(() => {
  // function getPicture() {
  //   const url = URL.createObjectURL(image);
  //   if (!url) {
  //     console.log("loading...");
  //   } else {
  //     console.log("loaded!");
  //   }
  //   setPicture(url);
  //   URL.revokeObjectURL(image);
  // }

  //   if (image) {
  //     getPicture();
  //   }
  //   return () => {
  //     URL.revokeObjectURL(image);
  //   };
  // }, [image, picture]);

  // useEffect(() => {
  //   if (userData) {
  //     setEmail(userData.email);
  //     setCountry(userData.country);
  //     setFirstName(userData.firstname);
  //     setLastName(userData.lastname);
  //   }
  // }, []);

  function handleFirstNameChange(e) {
    const name = e.target.value;

    if (name.length > 0) {
      setFirstName(name);
    } else {
      setFirstName(userData.firstname);
    }
  }

  function handleLastNameChange(e) {
    const name = e.target.value;

    if (name.length > 0) {
      setLastName(name);
    } else {
      setLastName(userData.lastName);
    }
  }
  function handleEmailChange(e) {
    const name = e.target.value;

    var mailformat =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (name.length > 0 && name.match(mailformat)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }

    if (name.length > 0) {
      setEmail(name);
    } else {
      setEmail(userData.email);
      setEmailError(false);
    }
  }
  function handleCountryChange(e) {
    const coun = e.target.value;

    if (coun.length > 0) {
      setCountry(coun);
    } else {
      setCountry(userData.country);
    }
  }

  function handleNewPassword(e) {
    const pass = e.target.value;

    if (pass.length > 0) {
      setNewPassword(pass);
    } else {
      setNewPassword("");
    }
  }

  function handleConfirmPassword(e) {
    const pass = e.target.value;

    if (pass.length > 0) {
      setConfirmPassword(pass);
    } else {
      setConfirmPassword("");
    }
  }

  async function changeProfile() {
    const profile = doc(db, "users", userData.id);
    await updateDoc(profile, {
      firstname: firstName ? firstName : userData.firstname,
      lastname: lastName ? lastName : userData.lastname,
      country: country ? country : userData.country,
    })
      .then(() => {
        toast.success("Updated successfully.");
        setIsSavingProfile(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "There was a problem with your request.\n \n Please try again later."
        );
        setIsSavingProfile(false);
      });
  }

  function changeEmail(email) {
    updateEmail(user, email)
      .then(() => {})
      .catch((error) => {
        if (error.message === "Firebase: Error (auth/email-already-in-use).") {
          toast.error("The selected email is currently unavailable.");
        }
        if (error.message === "Firebase: Error (auth/requires-recent-login).") {
          toast.error(
            "We require a recent login to make updates. \n \n Please re-login and try again."
          );
        } else {
        }
        setIsSavingProfile(false);
        // ...
      });
  }

  function changePassword() {
    if (!newPassword === confirmPassword) {
      toast.error(
        "Please make sure your new password and the confirmation match."
      );
    } else {
      updatePassword(user, newPassword)
        .then(() => {
          toast.success("Password Updated Successfully");
          setIsChangingPassword(false);
          reset();
        })
        .catch((error) => {
          toast.error(error.message);
          toast.error("There was an error. \n \n Please try again later.");
          setIsChangingPassword(false);
        });
    }
  }

  function reset() {}

  function handlePasswordChange() {
    setIsChangingPassword(true);
    changePassword();
  }

  function handleProfileSave(e) {
    e.preventDefault();

    setIsSavingProfile(true);
    // changeEmail(email);
    changeProfile();

    // }, 2000);
  }

  const blockUser = async () => {
    const q = doc(db, "users", id);

    try {
      await updateDoc(q, {
        blocked: true,
      });
      toast.success("Blocked successfully!");
      setSelectedDeleteAccount(false);
    } catch (error) {
      console.log(error);
    }
  };


  async function handleRemovePopup () {
    setIsRemovingPopup(true)
    const q = doc(db, "users", id);
    try {
      await updateDoc(q, {
        popup: false,
      });
      toast.success("Removed successfully!");
      setRemovedPopup(true)
      setIsRemovingPopup(false)
    } catch (error) {
      console.log(error);
      toast.error("Could not remove pop-up")
    }
  };

  const unblockUser = async () => {
    const q = doc(db, "users", id);

    try {
      await updateDoc(q, {
        blocked: false,
      });
      toast.success("Unblocked successfully!");
      setSelectedDeleteAccount(false);
    } catch (error) {
      console.log(error);
    }
  };

  function handleBlockUser() {
    setSelectedDeleteAccount(true);
    blockUser();

    // setTimeout(() => {
    //   setSelectedDeleteAccount(false);
    // }, 3000);

    // setShowDeleteAccountModal(true);
  }

  function handleUnBlockUser() {
    setSelectedDeleteAccount(true);
    unblockUser();

    // setTimeout(() => {
    //   setSelectedDeleteAccount(false);
    // }, 3000);

    // setShowDeleteAccountModal(true);
  }

  function handleBilling() {
    setIsBilling(true);

    setTimeout(() => {
      setBilling(true);
      setIsBilling(false);
    }, 500);
  }

  function handleUpgrade() {
    setIsUpgrading(true);

    setTimeout(() => {
      setUpgradeAccount(true);
      setIsUpgrading(false);
    }, 500);
  }

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <ThreeColumnLayout>
          {billing && (
            <BillingModal open={{ billing, setBilling }} user={userData} />
          )}
          {showDeleteAccountModal && (
            <DeleteAccountAdmin
              open={{ showDeleteAccountModal, setShowDeleteAccountModal }}
              user={user.id}
            />
          )}
          {upgradeAccount && (
            <UpgradeAccountModal
              open={{ upgradeAccount, setUpgradeAccount }}
              user={userData}
            />
          )}
          {placeTrade && (
            <PlaceTradeModal
              open={{ placeTrade, setPlaceTrade }}
              user={userData}
            />
          )}

             {addProfits && (
            <AddUserProfitsModal
              open={{ addProfits, setAddProfits }}
              user={userData}
              profits={profits}
            />
          )}

          {sendNotification && (
            <SendNotificationModal
              open={{ sendNotification, setSendNotification }}
              user={user}
            />
          )}

          {tradingProgress && (
            <TradingProgressModal
              open={{ tradingProgress, setTradingProgress }}
              user={userData}
            />
          )}

        {popup && (
            <PopupModal
              open={{ popup, setPopup }}
              user={userData}
            />
          )}

          {addSignalBalance && (
            <AddSignalBalanceModal
              open={{ addSignalBalance, setAddSignalBalance }}
              user={userData}
              bal={bal}
            />
          )}

{addSubscriptionBalance && (
            <AddSubscriptionBalanceModal
              open={{ addSubscriptionBalance, setAddSubscriptionBalance }}
              user={userData}
              bal={subBal}
            />
          )}

          {addDeposit && (
            <AddDepositModal open={{ addDeposit, setAddDeposit }} user={user} />
          )}

          <div className="cards">
            <Card style={{ height: "fit-content", paddingBottom: "0.7rem" }}>
              <SubText className="top">About</SubText>
              <div className="about">
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "flex-start",
                    margin: "0",
                    padding: "0",
                  }}
                >
                  {userData?.photoURL && (
                    <img
                      src={userData?.photoURL}
                      alt="user"
                      style={{
                        pointerEvents: "none",
                        // position: "absolute",
                      }}
                      className="profile"
                    />
                  )}

                  <ModalImage
                    large={userData.photoURL}
                    alt="profile"
                    className="modal"
                  />

                  {!userData.photoURL && (
                    <>
                      {!userData.firstname ? (
                        <img src="/svg-loaders/tail-spin.svg" alt="loading" />
                      ) : (
                        <span className="avatar">
                          {userData.firstname?.slice(0, 1)}
                        </span>
                      )}
                    </>
                  )}
                </div>

                <div className="desc">
                  <p style={{fontWeight: "bolder", fontSize: "18px"}}>
                    {userData.firstname} {userData.lastname}{" "}
                  </p>
                  <p>{userData.email} </p>
                  <p>{userData.phonenumber && userData.phonenumber} </p>
                </div>
              </div>
            </Card>

            {userData.accountType && (
              <Card>
                <SubText className="top">Account</SubText>
                <div className="body" style={{ padding: "1rem" }}>
                  <FeatureText style={{ display: "grid", gap: "0.45rem" }}>
                    <p className="blue">Account Type</p>
                    <span style={{ fontSize: "larger" }}>
                      {userData.accountType}
                    </span>
                  </FeatureText>
                </div>
              </Card>
            )}

            <Card>
              <SubText className="top">Actions</SubText>
              <div className="actions">
                <DescBox>
                  <p>Update profile photo</p>
                  <div>
                    <NeutralButton
                      disabled={isUploadingImage}
                      onClick={handleImageChange}
                      style={{
                        display: "flex",
                        textAlign: "left",
                      }}
                    >
                      {isUploadingImage ? (
                        <span
                          className="flex w-full"
                          style={{ placeContent: "center" }}
                        >
                          <img
                            src="/svg-loaders/tail-spin.svg"
                            alt="loading"
                            width={24}
                            height={24}
                          />
                        </span>
                      ) : (
                        <p>Tap to change profile photo</p>
                      )}
                    </NeutralButton>
                    <input
                      onChange={handleImageURL}
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      style={{ opacity: "0", position: "absolute" }}
                      ref={imageRef}
                    />
                  </div>
                </DescBox>

                <DescBox>
                  <p>Send billing request</p>
                  <NeutralButton
                    disabled={isBilling}
                    onClick={handleBilling}
                    style={{
                      display: "flex",
                      textAlign: "left",
                    }}
                  >
                    {isBilling ? (
                      <span
                        className="flex w-full"
                        style={{ placeContent: "center" }}
                      >
                        <img
                          src="/svg-loaders/tail-spin.svg"
                          alt="loading"
                          width={24}
                          height={24}
                        />
                      </span>
                    ) : (
                      <p>Tap to send billing</p>
                    )}
                  </NeutralButton>
                </DescBox>

                <DescBox>
                  <p>Add deposit</p>
                  <NeutralButton
                    disabled={isAddingDeposit}
                    onClick={handleAddDeposit}
                  >
                    {isAddingDeposit ? (
                      <span
                        className="flex w-full"
                        style={{ placeContent: "center" }}
                      >
                        <img
                          src="/svg-loaders/tail-spin.svg"
                          alt="loading"
                          width={24}
                          height={24}
                        />
                      </span>
                    ) : (
                      <p>Tap to add a deposit</p>
                    )}
                  </NeutralButton>
                </DescBox>

                <DescBox>
                  <p>Add signal balance</p>
                  <NeutralButton
                    disabled={isAddingDeposit}
                    onClick={handleAddSignalBalance}
                  >
                    {isAddingSignalBalance ? (
                      <span
                        className="flex w-full"
                        style={{ placeContent: "center" }}
                      >
                        <img
                          src="/svg-loaders/tail-spin.svg"
                          alt="loading"
                          width={24}
                          height={24}
                        />
                      </span>
                    ) : (
                      <p>Tap to add signal balance</p>
                    )}
                  </NeutralButton>
                </DescBox>

                <DescBox>
                  <p>Add subscription balance</p>
                  <NeutralButton
                    disabled={isAddingSubscriptionBalance}
                    onClick={handleAddSubscriptionBalance}
                  >
                    {isAddingSubscriptionBalance ? (
                      <span
                        className="flex w-full"
                        style={{ placeContent: "center" }}
                      >
                        <img
                          src="/svg-loaders/tail-spin.svg"
                          alt="loading"
                          width={24}
                          height={24}
                        />
                      </span>
                    ) : (
                      <p>Tap to add subscription balance</p>
                    )}
                  </NeutralButton>
                </DescBox>

                <DescBox>
                  <p>Trading progress</p>
                  <NeutralButton
                    disabled={isTradingProgress}
                    onClick={handleTradingProgress}
                  >
                    {isTradingProgress ? (
                      <span
                        className="flex w-full"
                        style={{ placeContent: "center" }}
                      >
                        <img
                          src="/svg-loaders/tail-spin.svg"
                          alt="loading"
                          width={24}
                          height={24}
                        />
                      </span>
                    ) : (
                      <p>Tap to set trading progress</p>
                    )}
                  </NeutralButton>
                </DescBox>

               {(userData?.popup || !removedPopup) && 
              ( <DescBox>
                  <p>Pop-up</p>
                  <NeutralButton
                    disabled={isPopup}
                    onClick={handlePopup}
                  >
                    {isPopup ? (
                      <span
                        className="flex w-full"
                        style={{ placeContent: "center" }}
                      >
                        <img
                          src="/svg-loaders/tail-spin.svg"
                          alt="loading"
                          width={24}
                          height={24}
                        />
                      </span>
                    ) : (
                      <p>Tap to send Pop-up</p>
                    )}
                  </NeutralButton>
                </DescBox>)
                }

              {/* {(!userData?.popup || removedPopup) &&  <DescBox>
                  <p>Pop-up</p>
                  <RedButton
                    disabled={isRemovingPopup}
                    onClick={handleRemovePopup}
                    style={{
                      display: "flex",
                      textAlign: "left",
                    }}
                  >
                    {isRemovingPopup ? (
                      <span
                        className="flex w-full"
                        style={{ placeContent: "center" }}
                      >
                        <img
                          src="/svg-loaders/tail-spin.svg"
                          alt="loading"
                          width={24}
                          height={24}
                        />
                      </span>
                    ) : (
                      <p style={{ color: "white" }}>
                        Tap to remove popup
                      </p>
                    )}
                  </RedButton>
                </DescBox>} */}

                <DescBox>
                  <p>Add profits</p>
                  <NeutralButton disabled={isAddingProfits} onClick={handleAddProfits}>
                    {isAddingProfits ? (
                       <span
                       className="flex w-full"
                       style={{ placeContent: "center" }}
                     >
                       <img
                         src="/svg-loaders/tail-spin.svg"
                         alt="loading"
                         width={24}
                         height={24}
                       />
                     </span>
                    ) : (
                      <p>Tap to add profits</p>
                    )}
                  </NeutralButton>
                </DescBox>


                <DescBox>
                  <p>Send notification</p>
                  <NeutralButton
                    disabled={isSendingNotification}
                    onClick={handleSendNoti}
                    style={{
                      display: "flex",
                      textAlign: "left",
                    }}
                  >
                    {isSendingNotification ? (
                      <span
                        className="flex w-full"
                        style={{ placeContent: "center" }}
                      >
                        <img
                          src="/svg-loaders/tail-spin.svg"
                          alt="loading"
                          width={24}
                          height={24}
                        />
                      </span>
                    ) : (
                      <p>Tap to send notification</p>
                    )}
                  </NeutralButton>
                </DescBox>

                {/* <DescBox>
                  <p>Place trade</p>
                  <NeutralButton
                    disabled={isTrading}
                    onClick={handleTrade}
                    style={{
                      display: "flex",
                      textAlign: "left",
                    }}
                  >
                    {isTrading ? (
                      <span
                        className="flex w-full"
                        style={{ placeContent: "center" }}
                      >
                        <img
                          src="/svg-loaders/tail-spin.svg"
                          alt="loading"
                          width={24}
                          height={24}
                        />
                      </span>
                    ) : (
                      <p>Tap to place a trade</p>
                    )}
                  </NeutralButton>
                </DescBox> */}

                <DescBox>
                  <p>Upgrade account</p>
                  <NeutralButton
                    disabled={isUpgrading}
                    onClick={handleUpgrade}
                    style={{
                      display: "flex",
                      textAlign: "left",
                    }}
                  >
                    {isUpgrading ? (
                      <span
                        className="flex w-full"
                        style={{ placeContent: "center" }}
                      >
                        <img
                          src="/svg-loaders/tail-spin.svg"
                          alt="loading"
                          width={24}
                          height={24}
                        />
                      </span>
                    ) : (
                      <p>Tap to upgrade account</p>
                    )}
                  </NeutralButton>
                </DescBox>

                <DescBox>
                  <p>Delete account</p>
                  <RedButton
                    disabled={isDeletingUser}
                    onClick={handleDelete}
                    style={{
                      display: "flex",
                      textAlign: "left",
                    }}
                  >
                    {isDeleting ? (
                      <span
                        className="flex w-full"
                        style={{ placeContent: "center" }}
                      >
                        <img
                          src="/svg-loaders/tail-spin.svg"
                          alt="loading"
                          width={24}
                          height={24}
                        />
                      </span>
                    ) : (
                      <p style={{ color: "white" }}>
                        Tap to delete this account
                      </p>
                    )}
                  </RedButton>
                </DescBox>

                <DescBox>
                  <p>Block Account</p>
                  <div>
                    {userData.blocked ? (
                      <BlueButton
                        disabled={selectedDeleteAccount}
                        onClick={handleUnBlockUser}
                        style={{
                          display: "flex",
                          textAlign: "left",
                        }}
                      >
                        {selectedDeleteAccount ? (
                          <span
                            className="flex w-full"
                            style={{ placeContent: "center" }}
                          >
                            <img
                              src="/svg-loaders/tail-spin.svg"
                              alt="loading"
                              width={24}
                              height={24}
                            />
                          </span>
                        ) : (
                          <p style={{ color: "white" }}>
                            Tap to Unblock this account
                          </p>
                        )}
                      </BlueButton>
                    ) : (
                      <RedButton
                        disabled={selectedDeleteAccount}
                        onClick={handleBlockUser}
                        style={{
                          display: "flex",
                          textAlign: "left",
                        }}
                      >
                        {selectedDeleteAccount ? (
                          <span
                            className="flex w-full"
                            style={{ placeContent: "center" }}
                          >
                            <img
                              src="/svg-loaders/tail-spin.svg"
                              alt="loading"
                              width={24}
                              height={24}
                            />
                          </span>
                        ) : (
                          <p style={{ color: "white" }}>
                            Tap to block this account
                          </p>
                        )}
                      </RedButton>
                    )}
                  </div>
                </DescBox>
              </div>
            </Card>
          </div>

          <Card style={{ height: "max-content", paddingBottom: "1.3rem" }}>
            <SubText className="top">Settings</SubText>
            <div className="settings">
              <Switches style={{ maxWidth: "280px" }}>
                {options.map((opt) => (
                  <button
                    value={opt}
                    key={opt}
                    className={activeOption === opt ? "active" : "switch"}
                    onClick={(e) => setActiveOption(e.target.value)}
                  >
                    {opt}
                  </button>
                ))}
              </Switches>

              <br />

              {activeOption === "Profile" && (
                <DataForm>
                  <TextInput>
                    <label htmlFor="email">Email</label>
                    <input
                      placeholder={userData.email}
                      type="email"
                      name="email"
                      onChange={handleEmailChange}
                    />

                    {emailError && (
                      <>
                        <p className="red">Please enter a valid email</p>
                        <br />
                      </>
                    )}
                  </TextInput>

                  <MultiInput>
                    <TextInput>
                      <label htmlFor="firstname">First Name</label>
                      <input
                        placeholder={userData.firstname}
                        name="firstname"
                        onChange={handleFirstNameChange}
                      />
                    </TextInput>
                    <TextInput>
                      <label htmlFor="lastname">Last Name</label>
                      <input
                        placeholder={userData.lastname}
                        name="lastname"
                        onChange={handleLastNameChange}
                      />
                    </TextInput>
                  </MultiInput>

                  <TextInput>
                    <label htmlFor="lastname">Country</label>
                    <input
                      placeholder={userData.country}
                      name="lastname"
                      onChange={handleCountryChange}
                    />
                  </TextInput>

                  <OutlinedBox style={{ maxWidth: mobile ? "100%" : "360px" }}>
                    <p className="infot">
                      Leave a field unchanged to retain its value.
                    </p>
                  </OutlinedBox>

                  <button
                    type="submit"
                    className={
                      isSavingProfile || emailError
                        ? "button disabled"
                        : "button submit"
                    }
                    onClick={handleProfileSave}
                    disabled={isSavingProfile || emailError}
                    style={{ display: "grid", placeContent: "center" }}
                  >
                    {isSavingProfile ? (
                      <img
                        src="/svg-loaders/tail-spin.svg"
                        alt="loading"
                        width={24}
                        height={24}
                      />
                    ) : (
                      <p>Save</p>
                    )}
                  </button>
                </DataForm>
              )}

              {activeOption === "Security" && (
                <DataForm>
                  <TextInput>
                    <label htmlFor="new">New Password</label>
                    <input
                      placeholder="New Password"
                      name="new"
                      onChange={handleNewPassword}
                      type="password"
                    />
                  </TextInput>

                  <TextInput>
                    <label htmlFor="confirm">Confirm New Password</label>
                    <input
                      placeholder="Confirm New Password"
                      name="new"
                      onChange={handleConfirmPassword}
                      type="password"
                    />
                  </TextInput>

                  <button
                    className={
                      isChangingPassword || !newPassword || !confirmPassword
                        ? "button disabled"
                        : "button submit"
                    }
                    onClick={handlePasswordChange}
                    disabled={
                      isChangingPassword || !newPassword || !confirmPassword
                    }
                    style={{
                      display: "grid",
                      textAlign: "left",
                      placeContent: "center",
                    }}
                  >
                    {isChangingPassword ? (
                      <span
                        className="flex w-full"
                        style={{ placeContent: "center" }}
                      >
                        <img
                          src="/svg-loaders/tail-spin.svg"
                          alt="loading"
                          width={24}
                          height={24}
                        />
                      </span>
                    ) : (
                      <p>Save</p>
                    )}
                  </button>
                </DataForm>
              )}
            </div>
          </Card>
          {/* </Wrapper> */}
        </ThreeColumnLayout>
      )}
    </>
  );
};

const ThreeColumnLayout = styled.div`
  display: grid;
  grid-template-columns: 360px auto;
  grid-template-rows: auto auto;
  box-sizing: border-box;
  gap: 1rem;
  height: fit-content;
  margin-top: 50px;
  padding-bottom: 60px;
  width: 100%;
  overflow-x: hidden;

  .avatar {
    width: 45px;
    place-content: center;
    height: 45px;
    align-items: center;
    /* padding: 0.5rem; */
    background-color: #c4c4c4;
    display: flex;
    color: #131824;
    border-radius: 50%;
  }

  .profile {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
  }

  input:invalid {
    border: 1px solid #e64b60;
  }

  @media screen and (max-width: 900px) {
    display: grid;
    grid-template-columns: auto;
  }

  .settings {
    padding: 1rem;
    display: grid;
    box-sizing: border-box;
  }

  .cards {
    display: grid;
    gap: 2rem;
    margin: 0;
    padding: 0;
  }

  .avatar {
    width: 20px;
    place-content: center;
    height: 20px;
    align-items: center;
    padding: 0.5rem;
    background-color: #c4c4c4;
    display: flex;
    color: #131824;
    border-radius: 50%;
  }

  .actions {
    padding: 1rem;
    display: grid;
    gap: 2rem;
  }

  .about {
    display: flex;
    padding: 1rem;
    text-align: left;
    gap: 1rem;
    align-items: center;
    height: max-content;

    .img {
      height: 60px;
      width: 60px;
      object-fit: cover;
      border-radius: 100%;
    }

    .desc {
      display: grid;
      gap: 0.2rem;
    }
  }
`;

const DataForm = styled.form`
  display: grid;
  gap: 1rem;
  max-width: 100%;
  box-sizing: border-box;

  input {
    box-sizing: border-box;
    width: 100%;
  }

  .button {
    max-width: 280px;
    margin-top: 2rem;
    justify-self: right;
    box-sizing: border-box;

    .out {
      width: 280px;
    }

    @media screen and (max-width: 900px) {
      max-width: 100%;
    }
  }
`;

const MultiInput = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;

  div {
    width: 100%;
  }

  @media screen and (max-width: 450px) {
    display: flex;
    flex-direction: column;
  }
`;

const TextInput = styled.div`
  /* max-width: 360px; */
  box-sizing: border-box;
  display: grid;
  gap: 0.5rem;
  max-width: 100%;

  label {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #a3a3a3;
  }

  input {
    display: grid;
    background: #1f273a;
    border: 1px solid #3a4867;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 1rem;
    font-size: 1rem;
    gap: 1rem;
    color: white;
    outline: none;
  }
`;

export default Profile;
