import { SubText } from "../styles";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { collection, query, getDocs, where } from "firebase/firestore";
import { db } from "../firebase/firebase";
import CircularLoader from "../pages/CircularLoader";
import ModalImage from "react-modal-image";

const SubscribeDepositHistory = ({ user }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [payments, setPayments] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const paymentsList = [];
    async function getPayments() {
      const q = query(
        collection(db, "subscribeDeposits"),
        where("user", "==", user.uid)
        // orderBy("date", "desc")
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        paymentsList.push(doc.data());
        setPayments(paymentsList);
      });
    }

    getPayments();
  }, [user]);

  useEffect(() => {
    if (!payments) {
      setIsLoading(true);
    }

    if (payments) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [payments]);

  function getTime(milliSeconds) {
    const seconds = milliSeconds / 1000;
    const minutes = seconds / 60;
    const hours = minutes / 60;
    const days = hours / 24;
    const weeks = days / 7;

    if (seconds > 60 && minutes < 60) {
      return (
        Math.floor(minutes) +
        (Math.floor(minutes) > 1 ? " minutes ago" : " minute ago")
      );
    }

    if (seconds > 60 && minutes > 60 && hours < 24) {
      return (
        Math.floor(hours) + (Math.floor(hours) > 1 ? " hours ago" : " hour ago")
      );
    }

    if (seconds > 60 && minutes > 60 && hours > 24 && days < 7) {
      return (
        Math.floor(days) + (Math.floor(days) > 1 ? " days ago" : " day ago")
      );
    }

    if (seconds > 60 && minutes > 60 && hours > 24 && days > 7) {
      return (
        Math.floor(weeks) + (Math.floor(weeks) > 1 ? " weeks ago" : " week ago")
      );
    }

    return (
      Math.floor(seconds) +
      (Math.floor(seconds) > 1 ? " seconds ago" : " seconds ago")
    );
  }

  const [approvedPayments, setApprovedPayments] = useState([]);
  const [declinedPayments, setDeclinedPayments] = useState([]);
  const [pendingPayments, setPendingPayments] = useState([]);

  useEffect(() => {
    const approvedPaymentsList = [];
    const pendingPaymentsList = [];
    const declinedPaymentsList = [];

    payments.forEach((payment) => {
      switch (payment.status) {
        case "pending":
          pendingPaymentsList.push(payment);
          break;
        case "approved":
          approvedPaymentsList.push(payment);
          break;
        case "declined":
          declinedPaymentsList.push(payment);
          break;
        default:
          break;
      }
    });

    setApprovedPayments(approvedPaymentsList);
    setPendingPayments(pendingPaymentsList);
    setDeclinedPayments(declinedPaymentsList);
  }, [payments, approvedPayments, declinedPayments, pendingPayments]);

  return (
    <Wrapper>
      <SubText className="top">Deposit History</SubText>
      {isLoading ? (
        <CircularLoader />
      ) : payments.length > 0 ? (
        <table className="scrollbar-hidden">
          <thead>
            <tr>
              <th>Ref</th>
              <th>Method</th>
              <th>Amount</th>
              <th>Date</th>
              {/* <th className="proof">Payment proof</th> */}
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {pendingPayments.map(
              (payment) =>
                payment.status === "pending" && (
                  <tr key={payment.ref} className="row">
                    <td className="ref">
                      <p>{payment.ref}</p>
                    </td>
                    <td>
                      <p>{payment.type}</p>
                    </td>
                    <td>
                      <p>{payment.amount}</p>
                    </td>
                    <td>
                      <p>{getTime(new Date() - payment.date.toDate())}</p>
                    </td>
                    {/* <td
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "flex-start",
                        margin: "0",
                        padding: "0",
                      }}
                    >
                      <button
                        style={{
                          pointerEvents: "none",
                        }}
                        className="actionButton"
                      >
                        <p className="actionText">View</p>
                      </button>
                      <ModalImage
                        large={payment.proof}
                        alt="deposit"
                        className="modal"
                      />
                    </td> */}
                    <td>
                      {payment.status === "pending" && (
                        <div style={{ position: "relative" }}>
                          <button
                            className="pending"
                            style={{
                              width: "100%",
                              boxSizing: "border-box",
                              padding: "0.21rem",
                            }}
                          >
                            <p>Pending</p>
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                )
            )}
            {approvedPayments.map(
              (payment) =>
                payment.status === "approved" && (
                  <tr key={payment.ref} className="row">
                    <td className="ref">
                      <p>{payment.ref}</p>
                    </td>
                    <td>
                      <p>{payment.type}</p>
                    </td>
                    <td>
                      <p>{payment.amount}</p>
                    </td>
                    <td>
                      <p>{getTime(new Date() - payment.date.toDate())}</p>
                      {/* {user.isVerified && <button>active</button>} */}
                      {/* {!user.isVerified && <button>passive</button>} */}
                    </td>
                    {/* <td
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "flex-start",
                        margin: "0",
                        padding: "0",
                      }}
                    >
                      <button
                        style={{
                          pointerEvents: "none",
                        }}
                        className="actionButton"
                      >
                        <p className="actionText">View</p>
                      </button>
                      <ModalImage
                        large={payment.proof}
                        alt="deposit"
                        className="modal"
                      />
                    </td> */}
                    <td
                      style={{
                        placeContent: "flex-end",
                        display: "flex",
                      }}
                    >
                      {payment.status === "approved" && (
                        <button
                          className="approved"
                          style={{
                            width: "100%",
                            boxSizing: "border-box",
                            padding: "0.21rem",
                          }}
                        >
                          <p>Approved</p>
                        </button>
                      )}
                    </td>
                  </tr>
                )
            )}
            {declinedPayments.map(
              (payment) =>
                payment.status === "declined" && (
                  <tr key={payment.ref} className="row">
                    <td className="ref">
                      <p>{payment.ref}</p>
                    </td>
                    <td>
                      <p>{payment.type}</p>
                    </td>
                    <td>
                      <p>{payment.amount}</p>
                    </td>
                    <td>
                      <p>{getTime(new Date() - payment.date.toDate())}</p>
                    </td>
                    {/* <td
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "flex-start",
                        margin: "0",
                        padding: "0",
                      }}
                    >
                      <button
                        style={{
                          pointerEvents: "none",
                        }}
                        className="actionButton"
                      >
                        <p className="actionText">View</p>
                      </button>
                      <ModalImage
                        large={payment.proof}
                        alt="deposit"
                        className="modal"
                      />
                    </td> */}
                    <td
                      style={{
                        placeContent: "flex-end",
                        display: "flex",
                      }}
                    >
                      {payment.status === "declined" && (
                        <button
                          className="declined"
                          style={{
                            width: "100%",
                            boxSizing: "border-box",
                            padding: "0.21rem",
                          }}
                        >
                          <p>Declined</p>
                        </button>
                      )}
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </table>
      ) : (
        <p
          style={{
            padding: "4rem",
            fontWeight: "600",
            color: "#a3a3a3",
            textAlign: "center",
            alignSelf: "center",
          }}
        >
          You haven't made any deposits yet.
        </p>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  margin-top: 30px;
  background: #131824;
  border-radius: 10px;
  max-width: 100%;

  .declined {
    /* border: 0.5px solid #1199fa; */
    border: none;
    border-radius: 6px;
    outline: none;
    background: rgba(230, 75, 96, 0.13);

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem;
      color: #e64b60;
    }
  }

  .approved {
    /* border: 0.5px solid #1199fa; */
    border: none;
    border-radius: 6px;
    outline: none;
    background: rgba(32, 188, 164, 0.13);

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem;
      color: #20bca4;
    }
  }

  .pending {
    background: rgba(35, 163, 251, 0.13);
    border-radius: 6px;
    border: none;
    outline: none;

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem 1rem;
      color: #1199fa;
    }
  }

  .actionMenu {
    appearance: none;
    position: absolute;
    left: -100px;
    z-index: 1000;
    top: -100px;
    /* bottom: -40px; */
    border: 0.5px solid #1f273a;
    background: #161c2a;
    border-radius: 8px;
    padding: 0.4rem;
    display: grid;
    gap: 0.5rem;

    p {
      padding: 0.5rem;
      cursor: pointer;
      transition: background-color 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    p:hover {
      background-color: #1f273a;
      border-radius: 4px;
    }
  }

  .action {
    align-self: flex-end;
  }

  .asset {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  .top {
    top: 0;
    width: 100%;
    background-color: #19202f;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  table {
    display: grid;
    gap: 1rem;
    padding: 1rem;
    overflow-x: auto;
    max-width: 100%;
    overflow-x: auto;

    .name {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      text-transform: capitalize;

      img {
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .ref,
    .email {
      box-sizing: border-box;

      p {
        text-overflow: ellipsis;
        max-width: 85px;
        overflow: hidden;
      }
    }
  }

  thead {
    border: 1px solid #1f273a;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 700;
    color: #828ea1;
    font-size: 14px;
    line-height: 18px;
  }

  tbody {
    font-weight: 510;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    display: grid;
    gap: 0.7rem;
    padding-bottom: 30px;

    .row {
      border-radius: 4px;
      cursor: pointer;
    }

    .row:hover {
      background-color: #1f273a;
    }
  }

  th,
  td {
    width: 144px;
    text-align: left;
  }

  tr {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
    box-sizing: border-box;
  }
`;

export default SubscribeDepositHistory;
